<template>
  <div class="row">
    <va-modal
      v-model="newGreenCardModal"
      :hide-default-actions="true"
      title="New Green Card"
      :fixed-layout="true"
      class="flex xs12"
    >
      <div>
        <va-input
          v-model="newGreenCard.id"
          placeholder="ID"
        />
      </div>
      <div>
        <b>Cards:</b>
        <va-button
          :disabled="loading"
          small
          color="info"
          icon="fa fa-plus-circle"
          @click="addCard(false)"
        />
      </div>      
      <div 
        :style="{ 
          border: '1px solid grey',
          borderRadius: '5px',
          padding: '10px',
          marginTop: '10px',
        }"
        v-for="(card, index) in newGreenCard.cards"
      > 
        <div>
          <div><u>Card {{index + 1}}:</u> 
              <va-button
                :disabled="loading"
                small
                title="Delete Card"
                color="danger"
                icon="fa fa-times"
                @click="deleteCard(index, true)"
              />
          </div>
          <div>
            <va-input
              v-model="card.title"
              placeholder="Title"
            />
          </div>
          <div>
            <va-input
              v-model.number="card.questionIndex"
              placeholder="Question index"
              type="number"
            />
          </div>
          <div>
            <va-input
              v-model.number="card.timeToAppear"
              placeholder="Time to appear"
              type="number"
            />
          </div>
          <div>
            <va-input
              v-model.number="card.timeToDisappear"
              placeholder="Time to disappear"
              type="number"
            />
          </div>
          <div>
            Card {{index + 1}} Texts:
            <va-button
              :disabled="loading || ((card.texts.length) >= maxText)"
              small
              color="info"
              icon="fa fa-plus-circle"
              @click="addTextToCard(index, false)"
            />
          </div>
          <div
            :style="{ 
              border: '1px solid grey',
              borderRadius: '5px',
              padding: '10px',
            }"
            v-for="(text, idx) in card.texts"
          >
            <div>
              Text {{idx + 1}}:
              <va-button
                :disabled="loading"
                small
                title="Remove Text"
                color="danger"
                icon="fa fa-times"
                @click="deleteTextCard(index, idx, true)"
               /></div>
            <div>
              <va-input
                v-model="text.keyText"
                placeholder="Key text"
              />
            </div>
            <div>
              <va-input
                v-model.number="text.time"
                placeholder="Time"
                type="number"
              />
            </div>
          </div>
        </div> 
       </div>
      <div>
        <va-button
          @click="saveNewGreenCard"
          color="#85bb65"
          :disabled="loading"
        >
          Save New Green Card          
          <Orbit-Spinner
            :color="'white'"
            v-if="loading"
          />
        </va-button>
      </div>
    </va-modal>
    <va-modal
      v-model="showModal"
      :hide-default-actions="true"
      title="Edit Green Card"
      :fixed-layout="true"
    >
      <div
        class="modal"
      >
        <div class="mb-4">
          <h3 class="mb-3 underline">
            ID
          </h3>
          <div>
            <va-input
              v-model="modalData.id"
              placeholder="ID"
            />
          </div>
         <div>
            <b>Cards:</b>
            <va-button
              :disabled="loading"
              small
              color="info"
              icon="fa fa-plus-circle"
              @click="addCard(true)"
            />
          </div>      
          <div 
            :style="{ 
              border: '1px solid grey',
              borderRadius: '5px',
              padding: '10px',
              marginTop: '10px',
            }"
            v-for="(card, index) in modalData.cards"
          >
            <div><u>Card {{index + 1}}:</u> 
              <va-button
                :disabled="loading"
                small
                title="Delete Card"
                color="danger"
                icon="fa fa-times"
                @click="deleteCard(index)"
              />
            </div>
            <div>
              <va-input
                v-model="card.title"
                placeholder="Title"
              />
            </div>
            <div>
              <va-input
                v-model.number="card.questionIndex"
                placeholder="Question index"
                type="number"
              />
            </div>
            <div>
              <va-input
                v-model.number="card.timeToAppear"
                placeholder="Time to appear"
                type="number"
              />
            </div>
            <div>
              <va-input
                v-model.number="card.timeToDisappear"
                placeholder="Time to disappear"
                type="number"
              />
            </div>
            <div>
              Card {{index + 1}} Texts:
              <va-button
                :disabled="loading || ((card.texts.length) >= maxText)"
                small
                color="info"
                icon="fa fa-plus-circle"
                @click="addTextToCard(index, true)"
              />
            </div>
            <div
              :style="{ 
                border: '1px solid grey',
                borderRadius: '5px',
                padding: '10px',
              }"
              v-for="(text, idx) in card.texts"
            >
              <div>
                Text {{idx + 1}}:
                <va-button
                :disabled="loading"
                small
                title="Remove Text"
                color="danger"
                icon="fa fa-times"
                @click="deleteTextCard(index, idx)"
               />
              </div>
              
              <div>
                <va-input
                  v-model="text.keyText"
                  placeholder="Key text"
                />
              </div>
              <div>
                <va-input
                  v-model.number="text.time"
                  placeholder="Time"
                  type="number"
                />
              </div>
            </div>
          </div> 
        </div>
      </div>

      <div :slot="'actions'">
        <va-button
          @click="cancelModal"
          color="#cccccc"
          :disabled="loading"
        >
          Cancel
          <Orbit-Spinner
            :color="'white'"
            v-if="loading"
          />
        </va-button>
        <va-button
          @click="saveEdit"
          color="#85bb65"
          :disabled="loading"
        >
          Save Green Card!
          <Orbit-Spinner
            :color="'white'"
            v-if="loading"
          />
        </va-button>
        <va-button
          @click="deleteData"
          :style="{ 'float': 'right' }"
          color="#d20000"
          :disabled="loading"
        >
          Delete
          <Orbit-Spinner
            :color="'white'"
            v-if="loading"
          />
        </va-button>
      </div>
    </va-modal>
    <div class="flex md12">
      <va-card :title="'Green Card'">
        <va-data-table
          :fields="fields"
          :data="greenCards"
          no-pagination
        >
          <template
            slot="actions"
            slot-scope="props"
          >
            <va-button
              flat
              small
              color="gray"
              @click="edit(props.rowData)"
              class="ma-0"
            >
              {{ $t('tables.edit') }}
            </va-button>

            <va-button
              flat
              small
              color="danger"
              @click="remove(props.rowData)"
              class="ma-0"
            >
              {{ $t('tables.delete') }}
            </va-button>
          </template>
        </va-data-table>
        <va-button
          :disabled="newGreenCardModal"
          @click="createGreenCard()"
        >
          New Green Card
          <Orbit-Spinner
            :color="'white'"
            v-if="newGreenCardModal"
          />
        </va-button>
      </va-card>
    </div>
  </div>
</template>

<script>

import firebase from 'firebase';
import {OrbitSpinner} from 'epic-spinners';

export default {
  name: 'GreenCard',
  data: function() {
    return {
      loading: false,
      greenCards: [],
      newGreenCardModal: false,
      maxText: 5,
      newGreenCard: {
        id: null,
        cards: [{
          title: null,
          questionIndex: null,
          timeToAppear: null,
          timeToDisappear: null,
          texts: [{
            keyText: null,
            time: null
          }]
        }]
      },
      modalData: {},
      showModal: false,
    };
  },
  components: {
    OrbitSpinner,
  },
  async created() {
    this.getGreenCards();
  },
  methods: {
    async getGreenCards() {
      this.greenCards = [];
      const greenCards = firebase.firestore().collection('greenCards');
      greenCards.get().then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          data.createdAt = data.createdAt.toDate();
          data.uid = doc.id;
          this.greenCards.push(data);          
        });
      });
    },

    createGreenCard() {
      this.newGreenCardModal = true;
    },

    deleteData() {
      this.remove(this.modalData);
    },
    deleteTextCard(cardIndex, textIndex, isNewCard = false) {
      if(!isNewCard){
        this.modalData.cards[cardIndex].texts.splice(textIndex, 1);
      }else{
        this.newGreenCard.cards[cardIndex].texts.splice(textIndex, 1);
      }
    },
    deleteCard(index, newGreenCard = false) {
      if(!newGreenCard){
        //add WasRemoved = true to the card
        if(!this.modalData.removedList) {
          this.modalData.removedList = [];
        }
        //get uid of the card by index
        const uid = this.modalData.cards[index].uid;
        this.modalData.removedList.push(uid);
        this.modalData.cards.splice(index, 1);
      }else{
        //add WasRemoved = true to the card
        if(!this.newGreenCard.removedList) {
                this.newGreenCard.removedList = [];
        }

        //get uid of the card by index
        const uid = this.newGreenCard.cards[index].uid;
        this.newGreenCard.removedList.push(uid);
        this.newGreenCard.cards.splice(index, 1);
      }
    
      
    },
    remove(greenCard) {
      this.$swal.fire({
        title: "Remove Green Card",
        icon: 'warning',
        html: 'Are you sure you want to remove the Green Card <b>(' + greenCard.id + ")</b>?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal = false;
          firebase.firestore().collection('greenCards').doc(greenCard.uid).delete().then(() => {
            this.getGreenCards();
          });
        }
      });
    },

    cancelModal() {
      this.showModal = false;
    },

    async edit(greenCard) {
      this.modalData = await this.getGreenCardById(greenCard.uid);
      this.showModal = true;
    },

    async getGreenCardById(id) {
      const response = await firebase.firestore().collection('greenCards').doc(id).get();
      if (response.empty) {
        return {};
      }
      const greenCard = response.data();
      greenCard.uid = response.id;
      greenCard.cards = [];
      const snapshot = await firebase.firestore().collection('greenCards').doc(id).collection('cards').get();
      if (!snapshot?.empty) {
        snapshot.forEach(doc => {
          const data = doc.data();
          data.uid = doc.id;
          greenCard.cards.push(data);
        });
      }
      return greenCard;
    },

    async saveEdit() {
      this.loading = true;
      const greenCard = firebase.firestore().collection('greenCards').doc(this.modalData.uid);
      await greenCard.update({
        id: this.modalData.id,
        updatedAt: new Date(),
      })
      if(this.modalData.removedList) {
        //loop through the list
        for (const uid of this.modalData.removedList) {
          //delete the card
          await firebase.firestore().collection('greenCards').doc(this.modalData.uid).collection('cards').doc(uid).delete();
        }
      }
      for (const card of this.modalData.cards) {
          if (card.uid) {
          const existingId = card.uid;
          const cardToUpdate = firebase.firestore().collection('greenCards').doc(this.modalData.uid).collection('cards').doc(existingId);
            delete card.uid;
            await cardToUpdate.update(card)
          } else {
            await firebase.firestore().collection('greenCards').doc(this.modalData.uid).collection('cards').add(card);
          }
      }
      this.showModal = false;
      this.getGreenCards();
      this.loading = false;
    },

    async saveNewGreenCard() {
      this.loading = true;
      const data = {
        id: this.newGreenCard?.id,
        createdAt: new Date(),
      };
      const response = await firebase.firestore().collection('greenCards').add(data);
      for (const card of this.newGreenCard.cards) {
        await firebase.firestore().collection('greenCards').doc(response.id).collection('cards').add(card);
      }      
      this.newGreenCardModal = false;
      this.getGreenCards();
      this.resetValues();
      this.loading = false;
    },
    resetValues() {
      this.newGreenCard = this.getEmptyGreenCard();
    },
    getEmptyGreenCard() {
      return {
        id: null,
        cards: [this.getEmptyCard()]
      }
    },
    getEmptyCard() {
      return {
        title: null,
        questionIndex: null,
        timeToAppear: null,
        timeToDisappear: null,
        texts: [this.getEmptyText()]
      }
    },
    getEmptyText() {
      return {
        keyText: null,
        time: null,
      };
    },
    addCard(editing) {
      if (editing) {
        this.modalData.cards.push(this.getEmptyCard());
      } else {
        this.newGreenCard.cards.push(this.getEmptyCard());
      }
    },
    addTextToCard(cardIndex, editing) {
      if (editing) {
        this.modalData.cards[cardIndex].texts.push(this.getEmptyText());
      } else {
        this.newGreenCard.cards[cardIndex].texts.push(this.getEmptyText());
      }
    },
  },

  computed: {
    fields() {
      return [{
        name: 'uid',
        title: 'Doc',
      }, {
        name: 'id',
        title: 'ID',
      }, {
        name: 'createdAt',
        title: 'Created At',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }];
    },
  },
};
</script>

